import { useCallback, useMemo, useRef, useState } from "react";
import ToasterContext from "./ToasterContext";
import IToast from "./IToast";


export default function ToasterProvider({ children }: { children: React.ReactNode }) {
    const ref = useRef<ReturnType<typeof setTimeout> | null>(null);
    const ref2 = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [toast, setToast] = useState<IToast | null>(null);
    const [show, setShow] = useState(false);

    const memoSetToast = useCallback((toast: IToast) => {
        setToast(toast);
        setShow(true);
        if (ref.current) clearTimeout(ref.current);
        ref.current = setTimeout(() => {
            setShow(false);
            if (ref2.current) clearTimeout(ref2.current);
            ref2.current = setTimeout(() => setToast(null), 300);
        }, 3000);
    }, [setToast, setShow]);

    const ctx = useMemo(() => ({
        toast,
        show,
        setToast: memoSetToast,
    }), [toast, show, memoSetToast]);

    return <ToasterContext.Provider value={ctx}>
        {children}
    </ToasterContext.Provider>;
}